import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Happy Valentines Day!`}</strong></p>
    <p><em parentName="p">{`Circuit the following: `}</em></p>
    <p>{`Legless Wall Balls 4×2 (20/14)`}</p>
    <p>{`Bench Press 4×2\\@90% 1RM`}</p>
    <p>{`Band Face Pulls 4×10`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20/15 Calorie Assault Bike`}</p>
    <p>{`100ft Bear Crawl`}</p>
    <p>{`10 Bar Dips`}</p>
    <p>{`200ft Shuttle Run`}</p>
    <p>{`20 Ring Pushups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We are sold out for the Granite Games Spring Throwdown!  We do
still need a few judges (level 1 or past judging experience is
preferred). February 16th is the last day to get your volunteer t shirt
so if you can, please consider helping.  Sign up to volunteer
at: `}<a parentName="em" {...{
            "href": "https://competitioncorner.net/events/3164"
          }}>{`https://competitioncorner.net/events/3164`}</a></em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      